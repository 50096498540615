import { ToastContainer } from 'react-toastify'
import { CContainer, CHeader } from "@coreui/react";
import SideBar from "./navigation/SideBar";
import NavBar from "./navigation/NavBar";

import 'react-toastify/dist/ReactToastify.min.css'

interface LayoutProps {
  ready: boolean;
  children: any;
  authenticated: boolean;
}

const Layout = ({ ready, children, authenticated }: LayoutProps) => {

  if (!authenticated) {
    return <CContainer fluid={true}>{children}</CContainer>;
  }
  
  return <>
    <div className='d-flex'>
      <SideBar ready={ready} authenticated={authenticated} />
      <div className='c-wrapper'>
        <CHeader className='align-items-center'>
          <NavBar ready={ready && authenticated}></NavBar>
        </CHeader>
        <div className='c-body'>
          <div className='c-main'>
            <CContainer fluid={true}>{children}</CContainer>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
    </>
}

export default Layout;