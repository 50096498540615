import { useState } from 'react'

import { FilteredReportRequestDTO } from "api/clients";
import { ReportFilterSubmission } from "common/types"
import { default as ReactSelect, SingleValue } from "react-select";
import { SelectOption, SelectOptionGroup } from 'common/selectOption';
import { DateTime} from "luxon"

interface FiltersProps {
  handleSubmit: ReportFilterSubmission
  isSearching: boolean
  initialFilters: FilteredReportRequestDTO
  kitTypes?: SelectOption[]
  locations?: SelectOptionGroup[]
  includeResearch?: boolean
}

export default function ReportFilters({ handleSubmit, isSearching, initialFilters, kitTypes, locations, includeResearch }: FiltersProps) {

  const [filters, setFilters] = useState<FilteredReportRequestDTO>(initialFilters);
  const [researchPrograms] = useState<SelectOption[]>(new Array<SelectOption>( { label: "Include", value: true }, { label: "Exclude", value: false }))

  /* useEffect(() => {
    setFilters(FilteredReportRequestDTO.fromJS(initialFilters))
  }, [initialFilters]) */

  let resetForm = () => {
    setFilters(FilteredReportRequestDTO.fromJS({
      startDate: initialFilters.startDate,
      endDate: initialFilters.endDate,
      kitTypeID: undefined,
      locationID: undefined,
      researchProgram: undefined
    }))
  }

  let handleInputChange = (event: any) => {
    const target = event.target;

    var date = target.value.length > 0 ? DateTime.fromISO(target.value) : undefined

    setFilters(FilteredReportRequestDTO.fromJS({
      ...filters, 
      [target.name]: date
    }))
  }

  let handleSelectChange = (key: string, value: any) => {
    setFilters(FilteredReportRequestDTO.fromJS({
      ...filters,
      [key]: value
    }))

  }

  let submitForm = (event: any) => {
    event.preventDefault();

    if(!validateForm()) {
      return;
    }

    handleSubmit(filters!);

    return false;
  }

  let validateForm = (): boolean => {
    /* const {
      startDate,
      endDate,
    } = this.state.data; */

    return true; // startDate !== undefined && endDate !== undefined
  };

  return (
    <>
    {filters !== undefined && 
    <form onSubmit={submitForm}>
      <div className="row">
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="date-from">Date from:</label>
            <div className="col-8">
              <input 
              id="date-from" 
              className="form-control" 
              name="startDate"
              value={filters!.startDate !== undefined ? filters!.startDate!.toISODate() : ""} 
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="date-to">Date to:</label>
            <div className="col-8">
              <input 
              id="date-to" 
              className="form-control" 
              name="endDate"
              value={filters!.endDate !== undefined ? filters!.endDate!.toISODate() : ""}
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>

        {locations !== undefined && 
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="locationID">Location:</label>
            <div className="col-8">
              <ReactSelect
              isClearable={true}
              value={locations.flatMap(x => x.options).filter(x => x.value === filters!.locationID)}
              id="locationID" 
              name="locationID"
              options={locations!}
              onChange={(newValue: SingleValue<SelectOption>) => handleSelectChange("locationID", newValue?.value)} />
            </div>
          </div>
        </div>
        }

        {kitTypes !== undefined && 
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="kitTypeID">Kit Type:</label>
            <div className="col-8">
              <ReactSelect
              isClearable={true}
              value={kitTypes.filter(x => x.value === filters!.kitTypeID)}
              id="kit-type" 
              name="kitTypeID"
              options={kitTypes!}
              onChange={(newValue: SingleValue<SelectOption>) => handleSelectChange("kitTypeID", newValue?.value)} />
            </div>
          </div>
        </div>
        }

        {includeResearch &&
        <div className="col col-md-6">
          <div className="form-group form-row row">
            <label className="col-form-label col-4" htmlFor="researchProgram">Research Program:</label>
            <div className="col-8">
              <ReactSelect
              isClearable={true}
              value={researchPrograms.filter(x => x.value === filters!.isResearchProgram)}
              id="isResearchProgram" 
              name="isResearchProgram"
              options={researchPrograms!}
              onChange={(newValue: SingleValue<SelectOption>) => handleSelectChange("isResearchProgram", newValue?.value)} />
            </div>
          </div>
        </div>

        }

      </div>

      <div className="text-right">
        <button type="button" className="btn btn-link" onClick={(e) => resetForm()}>Reset</button>
        <button type="submit" className="btn btn-primary ml-2" disabled={isSearching}>Submit</button>
      </div>
    </form>
  }
  </>
  )
}
