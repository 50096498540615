import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../index";
import {
  ApplicationUserDTO,
  LookupsClient
} from "../../api/clients";
import {
  SET_LOOKUPS,
  SET_FETCHING_LOOKUPS,
  SET_USER,
  LookupsActionTypes,
  LookupsState
} from "./types";
// TypeScript infers that this function is returning SetLookupsAction
export function setLookups(lookups: LookupsState): LookupsActionTypes {
  return {
    type: SET_LOOKUPS,
    lookups: lookups
  };
}
// TypeScript infers that this function is returning SetFetchingLookupsAction
export function setFetchingLookups(isFetching: boolean): LookupsActionTypes {
  return {
    type: SET_FETCHING_LOOKUPS,
    isFetching: isFetching
  };
}
export const fetchLookups = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => async (dispatch, getState): Promise<void> => {
  dispatch(setFetchingLookups(true));
  dispatch(
    setLookups({
      data: await new LookupsClient().getLookups(),
      isFetching: false
    })
  );
};
export function setUser(user: ApplicationUserDTO): LookupsActionTypes {
  return {
    type: SET_USER,
    user: user
  };
}
export const actionCreators = {
  fetchLookups,
  setUser
};