import { useEffect, useRef, useState } from "react";
import {
  FormSubmissionDTO,
  KitTypesClient
} from "api/clients";
import SGrid from "components/common/SGrid";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Loading from "components/common/Loading";
import { AgGridReact } from "ag-grid-react";

interface FulfillmentProps {
    ToggleSelected: (id: number, RefreshGrid:any) => void, 
    CheckIfToggled: (id: number) => boolean, 
    SelectAllRowsButton: (RefreshGrid:any) => void,
    ExportGirdToCSV:() => void, 
    EditAddressGridButton: (userID:string, kitID:number) => void,
    orders: FormSubmissionDTO[] | null,
    isEditable: boolean,
}

        
export default function FulfillmentGrid({ ToggleSelected, CheckIfToggled, SelectAllRowsButton, ExportGirdToCSV, orders, isEditable, EditAddressGridButton} : FulfillmentProps) {
    const gridRef = useRef<AgGridReact>(null);
    const EditLocationRenderer = (props: ICellRendererParams) => {
        return <div className="ag-cell-h-full d-flex align-items-center justify-content-center">
            <div>
                <input type="checkbox" onChange={() => ToggleSelected(props.data, RefreshGrid)  } checked={CheckIfToggled(props.data.id) }/>
            </div>
        </div>
    }

    const EditAddressRenderer = (props: ICellRendererParams) => {
        return <div className="ag-cell-h-full d-flex align-items-center justify-content-end">
            <div className="btn-group btn-group-sm">
                <button className="btn btn-outline-primary" onClick={() => EditAddressGridButton(props.data.userID, props.data.id)}><i className="fas fa-pencil-alt"></i></button>
                
            </div>
        </div>
    }

    const RefreshGrid = () => {
        gridRef.current?.api?.redrawRows();
    }

    const [kitsClient] = useState(new KitTypesClient())
    const [loading, setLoading] = useState(true)
    let [colDefs, setColDefs] = useState<ColDef[]>([])

    useEffect(() => {
        setLoading(true);

        kitsClient.getAll().then((res: any) => {
            setColDefs([
                { cellRenderer: 'editLocationRenderer',flex: 1, filter: true, field: 'selectedRow' },
                { headerName: 'User ID', flex: 1, filter: true, field: 'userID' },
                { headerName: 'Source', flex: 1, filter: true, field: 'submissionSource' },
                { headerName: 'Date', flex: 1, filter: true, field: 'submissionDate' },
                { headerName: 'First Name', flex: 1, filter: true, field: 'firstName' },
                { headerName: 'Last Name', flex: 1, filter: true, field: 'lastName' },
                { headerName: 'Street', flex: 1, filter: true, field: 'streetName' },
                { headerName: 'Street Number', flex: 1, filter: true, field: 'buildingNumber' },
                { headerName: 'Unit', flex: 1, filter: true, field: 'unit' },
                { headerName: 'City', flex: 1, filter: true, field: 'city' },
                { headerName: 'Postal Code', flex: 1, filter: true, field: 'postalCode' },
                { headerName: 'PROV', flex: 1, filter: true, field: 'province' },
                { headerName: 'Transaction ID', flex: 1, filter: true, field: 'transactionNumber' },
                ...res.data.map((kitType: any) => {
                    return {
                        headerName: kitType.name,
                        flex: 1,
                        filter: true,
                        field: kitType.key,
                        valueGetter: (params: any) => {
                            let rowData = (params.data as FormSubmissionDTO)
                            var numNeeded = rowData.kits?.filter(x => x.kitTypeCode === kitType.key).reduce((total, currKit) => total + currKit.numNeeded, 0) ?? 0;
                            return numNeeded 
                        }
                    }
                }),
                isEditable ? {cellRenderer: 'editAddressRenderer', flex: 1, filter: false, field: '' } : {},
            ])

            setLoading(false)
        })
    }, [ kitsClient, isEditable ])
    
    return (
    <>
        {orders === null 
        ? <div>No orders to display</div>
        : loading 
            ? <Loading label="Loading Kit Types" color="primary"></Loading>
            : (
            <>
                <div >
                    <button type="button" className="btn btn-primary mb-2 float-left" onClick={() =>SelectAllRowsButton(RefreshGrid)}>Select All Rows</button>
                    <button type="button" className="btn btn-primary mb-2 float-right" onClick={ExportGirdToCSV}><i className="cil-cloud-download btn-icon mr-2"></i> Export</button>
                </div>
                
                <SGrid
                    frameworkComponents={{ "editLocationRenderer": EditLocationRenderer, "editAddressRenderer": EditAddressRenderer}}
                    columnDefs={colDefs}
                    data={orders}
                    gridRef={gridRef}
                ></SGrid>
                
            </>
            )
        }
    </>
    )
 }