import { Component } from "react";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import FulfilmentTabs from "./Tabs";
import FulfilmentGrid from "./FulfillmentGrid";
import { ApiResponse, FileResponse, FilteredReportRequestDTO, FormSubmissionDTO, KitShipmentStatusClient, KitsOrderedRequestDTO, ShipmentStatus, SubmissionSource,ShipOrdersDTO } from "api/clients";
import { toast } from "react-toastify";
import DownloadFile from "api/downloadFile";
import { Modal } from "react-bootstrap";

interface FulfillmentDeliveryKitsProps {}

interface FulfillmentDeliveryKitsState {
  gridData: FormSubmissionDTO[] | null;
  dataRecived: boolean
  kitsNeeded: number[]
  editAddressOpen: boolean
  street: string
  streetNumber: string
  unitNumber: string
  city: string
  province: string
  postalCode: string
  country: string
  user: string
  kitID: number
  selectedOrders: ShipOrdersDTO[]

}

export default class FulfillmentDeliveryKits extends Component<FulfillmentDeliveryKitsProps,FulfillmentDeliveryKitsState> {
  constructor(props: FulfillmentDeliveryKitsProps) {
    super(props);
    this.state = {
      gridData: null,
      dataRecived: false,
      kitsNeeded: [],
      editAddressOpen: false,
      street: "",
      streetNumber: "",
      unitNumber: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      user: "",
      kitID: 0,
      selectedOrders: []
    };
  }
  openModal = () => this.setState({ editAddressOpen: true });
  closeModal = () => this.setState({ editAddressOpen: false }); 


  ToggleSelected = (row: any, RefreshGrid:any ) => {
    if(this.state.selectedOrders.find(x => x.orderID === row.id)){
      this.setState({selectedOrders: this.state.selectedOrders.filter(x => x.orderID !== row.id)})
    }
    else{
      var order = new ShipOrdersDTO();
      order.orderID = row.id;
      order.submissionSource = SubmissionSource[row.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource
      this.setState({selectedOrders: this.state.selectedOrders.concat(order)})
    }
    RefreshGrid()
  }

  ExportGirdToCSV(){
    var orderReq = new KitsOrderedRequestDTO();
    orderReq.status = [ShipmentStatus.To_Fulfil, ShipmentStatus.Returned]
    orderReq.filters = new FilteredReportRequestDTO();
    (new KitShipmentStatusClient()).getKitsOrderedCsv(orderReq).then((resp: FileResponse | null) => {
      DownloadFile(resp);
    }).catch((err: any) => {
      toast.error("Error getting report, please try again.");
      console.error('Error getting report:', err)
    });
  }

  EditInventory = () => {
    if(this.state.selectedOrders.length > 0){
      new KitShipmentStatusClient().shipOrders(this.state.selectedOrders).then((response: ApiResponse) => {
        if(response.responseCode === 200){
          toast.success("Orders marked as shipped");
          this.GetOrders(new FilteredReportRequestDTO());
          this.setState({selectedOrders: []})
        }
        else{
          toast.error("Error marking orders as shipped");
        }
      }).catch((err: any) => {
        toast.error("Error updating inventory, please try again.");
      })
    }else{
      toast.error("No orders selected");
    }
  }

  SelectAllRowsButton = (RefreshGrid:any) => {
    if (this.state.selectedOrders.length === this.state.gridData?.length){
      this.setState({selectedOrders: []})
    }
    else{
      this.setState({selectedOrders: this.state.gridData?.map(x => new ShipOrdersDTO({ orderID: x.id, 
        submissionSource: x.submissionSource !== undefined ? SubmissionSource[x.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource : SubmissionSource.Unknown,
       }) ) ?? []})
    }

    RefreshGrid();
  }

  CheckIfToggled=(id: number) => {
    return this.state.selectedOrders.find(x => x.orderID === id) !== undefined
  }

  MarkArchived = () => {
    new KitShipmentStatusClient().updateOrdersStatus(this.state.selectedOrders.map(x => x.orderID), ShipmentStatus.Archived, "", null, null, true ).then(() => {
      this.GetOrders(new FilteredReportRequestDTO())
      this.setState({selectedOrders: []})
    }).catch((err: any) => {
      toast.error("Error updating inventory, please try again.");
      console.error('Error updating inventory:', err)
    })
  }

  GetOrders = (filters: FilteredReportRequestDTO) =>{
    var orderReq = new KitsOrderedRequestDTO();
    orderReq.status = [ShipmentStatus.To_Fulfil, ShipmentStatus.Returned]
    orderReq.filters = filters;
    (new KitShipmentStatusClient()).getKitsOrdered(orderReq)
    .then((resp: any | null) => {
        this.setState({gridData: resp, dataRecived: true})
    }).catch((err: any) => {
        toast.error("Error getting report, please try again.");
        console.log('Error getting report:' + err)
    })
  }
  
  EditAddress = () => {
    new KitShipmentStatusClient().updateOrderAddress(this.state.kitID, this.state.street, this.state.streetNumber, this.state.unitNumber, this.state.city, this.state.province, this.state.postalCode)
    .then((response: ApiResponse) => {
      if(response.responseCode === 200){
        this.GetOrders(new FilteredReportRequestDTO())
        this.closeModal()
        toast.success("Address Updated");
        this.setState({street: "", streetNumber: "", unitNumber: "", city: "", province: "", postalCode: "", country: "", user: "", kitID: 0})
      }
      else{
        toast.error("Error updating address, please try again.");
      }
    }).catch((err: any) => {
      toast.error("Error updating address, please try again.");
      console.error('Error updating address:', err)
    })
  }

  EditAddressGridButton = (userID: string, id: number) => {
    this.setState({user: userID, kitID: id})
    this.openModal()
  }

  componentDidMount() {this.GetOrders(new FilteredReportRequestDTO())}

  render() {
    return (
        <WrappedPage breadcrumbs={[{ label: "Fulfilment" }]}>
          <FulfilmentTabs></FulfilmentTabs>
          <CCard>
            <CCardBody className="c-body-full">
              <FulfilmentGrid ToggleSelected={this.ToggleSelected} SelectAllRowsButton={this.SelectAllRowsButton} CheckIfToggled={this.CheckIfToggled} orders={this.state.gridData} ExportGirdToCSV={this.ExportGirdToCSV} isEditable={true} EditAddressGridButton={this.EditAddressGridButton}/>
              {this.state.gridData !== null ? (<div className="text-right mt-2">
                <button type="submit" className="btn btn-primary ml-2" onClick={this.EditInventory }><i className="cil-check-alt btn-icon mr-2"></i> Mark As Shipped</button>
                <button type="submit" className="btn btn-primary ml-2" onClick={this.MarkArchived }><i className="cil-check-alt btn-icon mr-2"></i> Mark As Archived</button>
              </div>): (null)}
            </CCardBody>
          </CCard>
          <Modal show={this.state.editAddressOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Address for User: {this.state.user}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="form-group">
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Street: </label>
              <input type="street" 
                    className="form-control"
                    style={{width: "75%", float: "left"}}
                    id="inputStreet" 
                    placeholder="Street"
                    value={this.state.street}
                    onChange={(e) => this.setState({street: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Street Number: </label>
              <input type="number" 
                    className="form-control" 
                    style={{width: "75%", float: "left"}}
                    id="inputStreetNumber" 
                    placeholder="Street Number"
                    value={this.state.streetNumber}
                    onChange={(e) => this.setState({streetNumber: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Unit Number: </label>
              <input type="number" 
                    className="form-control"
                    style={{width: "75%", float: "left"}} 
                    id="inputUnitNumber" 
                    placeholder="Unit Number"
                    value={this.state.unitNumber}
                    onChange={(e) => this.setState({unitNumber: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>City: </label>
              <input type="city" 
                    className="form-control" 
                    style={{width: "75%", float: "left"}} 
                    id="inputCity" 
                    placeholder="City"
                    value={this.state.city}
                    onChange={(e) => this.setState({city: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Province: </label>
              <input type="state" 
                    className="form-control"
                    style={{width: "75%", float: "left"}} 
                    id="inputProvince" 
                    placeholder="Province"
                    value={this.state.province}
                    onChange={(e) => this.setState({province: e.target.value})}>
              </input>
              <label style={{width:"25%",float:"left",marginTop:"5px", paddingRight:"5px"}}>Postal Code: </label>
              <input type="zip" 
                    className="form-control" 
                    style={{width: "75%", float: "left"}} 
                    id="inputPostalCode" 
                    placeholder="Postal Code"
                    value={this.state.postalCode}
                    onChange={(e) => this.setState({postalCode: e.target.value})}>
              </input>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={this.EditAddress}>Submit</button>
            </Modal.Footer>
          </Modal>
        </WrappedPage>
        
      )
    }
}