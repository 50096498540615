import { Component } from "react";
import SCardHeader from "components/common/CardHeader";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";

export default class Home extends Component<{}, {}> {

  render() {
    return (
      <WrappedPage breadcrumbs={[]}>
        <CCard>
          <SCardHeader
            title="UHT I'm Ready Admin"
          ></SCardHeader>
          <CCardBody></CCardBody>
        </CCard>
      </WrappedPage>
    );
  }
}