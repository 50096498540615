import IRoute from "types/IRoute";

import Home from "components/pages/Home";

import KitTypesHome from "components/pages/kitTypes/Home";

import LocationsHome from "components/pages/locations/Home";
import LocationsAdd from "components/pages/locations/Add";
import LocationsEdit from "components/pages/locations/Edit";

import ManageInventory from "components/pages/inventory/Home";
import ReceiveShipment from "components/pages/inventory/ReceiveShipment";
import DisposeInventory from "components/pages/inventory/DisposeInventory";
import TransactionsReport from "components/pages/inventory/TransactionsReport";
import FulfillDeliveryKits from "components/pages/fulfilment/FulfillmentDeliveryKits";
import KitsMarkedShipped from "components/pages/fulfilment/KitsMarkedShipped";
import OrdersArchived from "components/pages/fulfilment/OrdersArchived";

import KitsOrderedReport from "components/pages/reporting/KitsOrdered";
import KitsRequestedVsClaimedReport from "components/pages/reporting/KitsRequestedVsClaimed";
import PickupsClaimedVsDeliveriesReport from "components/pages/reporting/PickupsClaimedVsDeliveries";
import SurveysVsScanned from "components/pages/reporting/SurveysVsScanned";

const links: IRoute[] = [
  { path: "/", component: Home, exact: true },
  { path: "/kit-types", component: KitTypesHome },
  { path: "/locations", component: LocationsHome, exact: true },
  { path: "/locations/add", component: LocationsAdd },
  { path: "/locations/edit/:id", component: LocationsEdit },
  { path: "/inventory", component: ManageInventory, exact: true },
  { path: "/inventory/receive-shipment", component: ReceiveShipment },
  { path: "/inventory/dispose", component: DisposeInventory },
  { path: "/inventory/export-transactions", component: TransactionsReport },
  { path: "/reporting/kits-ordered", component: KitsOrderedReport },
  { path: "/reporting/pickups-claimed-vs-deliveries", component: PickupsClaimedVsDeliveriesReport },
  { path: "/reporting/kits-requested-vs-claimed", component: KitsRequestedVsClaimedReport },
  { path: "/fulfillment/fulfill-delivery-kits", component: FulfillDeliveryKits },
  { path: "/fulfillment/return-shipped-kits", component: KitsMarkedShipped },
  { path: "/fulfillment/orders-archived", component: OrdersArchived },
  { path: "/reporting/surveys-vs-scanned", component: SurveysVsScanned }
];

export default links;
