function AuthContainer(props: any) {
    return <section className="auth-page">
      <div className="d-flex justify-content-center">
        <div className="container container-fluid shadow">
          <main role="main">
            <div className="mb-3">
              <svg width="204.8px" height="80px" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 548">
                <path className="cls-3" d="M273.93,81.59c2.86-2.59,5.1-4.74,7.46-6.73A73.63,73.63,0,0,1,306,61.48c11.91-4,24.07-5,36.57-4.08,13.39,1,26.1,4.12,37.77,10.83A63.15,63.15,0,0,1,400.1,86.49c.55.76,1.11,1.51,2.36,3.2a15,15,0,0,1,1.27-2.45C413.84,74.75,426.89,66.49,442,61.72a102.19,102.19,0,0,1,62.14-.21A70.17,70.17,0,0,1,539.36,86c6.62,8.48,10.75,18.35,13.44,28.83,2.92,11.39,3.73,23,3.77,34.65.12,37.62.07,75.24.08,112.86,0,3.78,0,3.8-3.83,3.8l-66.42,0h-1.74a16.83,16.83,0,0,1-.35-2.34c0-35.09.08-70.19,0-105.28A60.63,60.63,0,0,0,480.1,135a27.52,27.52,0,0,0-24.55-17c-6.53-.12-12.9.6-18.77,3.66-10.25,5.34-16,14.14-17.95,25.21a122,122,0,0,0-1.71,20.74c-.18,31.88-.09,63.75-.1,95.62v2.63c-1,.07-2,.19-2.87.19-22.14,0-44.27,0-66.41,0-2.1,0-2.85-.4-2.84-2.7q.08-50.15,0-100.31c0-8.55-.33-17-3.18-25.25-4.31-12.43-12.6-19.13-25.86-19.74-10.75-.49-20.34,2.31-27.9,10.38a37.15,37.15,0,0,0-9.61,21.54,172,172,0,0,0-1.15,19.78c-.1,30.74,0,61.48,0,92.22,0,4,0,4-4.16,4-21.7,0-43.41,0-65.11.05-2.31,0-2.91-.6-2.91-2.9q.1-77.71.07-155.44c0-15.06,0-30.13,0-45.19,0-1.79.57-2.21,2.25-2.21q26.79.08,53.59,0c3.48,0,7,.08,10.44,0,1.85-.06,2.43.64,2.39,2.44-.08,4.78,0,9.57,0,14.36C273.72,78.39,273.83,79.58,273.93,81.59Z"></path>
                <path className="cls-4" d="M188.77,463.34l57.35,83.35c-1.53.11-2.35.23-3.17.23-25.1,0-50.21,0-75.31.07a4.74,4.74,0,0,1-4.43-2.44q-22.44-33-44.92-65.92a4.72,4.72,0,0,0-4.46-2.36c-11.59.1-23.19.12-34.78,0-2.48,0-3.06.79-3,3.14.08,21.33,0,42.67,0,64,0,3.54,0,3.54-3.62,3.54-23,0-46-.05-69,0C.74,547,0,546.27,0,543.62q.09-129.86,0-259.7c0-2.06-.08-3.64,2.9-3.64,41.23.11,82.46-.07,123.68.21,19.71.14,39,3.36,57.16,11.44,30.05,13.4,49.45,35.68,55.26,68.59,3.47,19.68,2,39-5.91,57.55a79.48,79.48,0,0,1-24.51,32.29c-5.6,4.35-11.84,7.87-17.8,11.76C190.25,462.49,189.65,462.82,188.77,463.34ZM76.19,340.6c0,.81-.11,1.32-.11,1.83,0,24.36,0,48.73-.07,73.09,0,2.08,1,2.19,2.55,2.19,12,0,24,.08,36.06-.06,7.55-.1,15.08-.42,22.47-2.45,10.92-3,19.37-8.85,23.84-19.57,3.2-7.69,3.42-15.69,2.35-23.82-1.33-10.12-6-18.2-14.59-23.88-8.34-5.53-17.88-7.14-27.59-7.35-14.1-.3-28.22-.1-42.33-.1C78,340.48,77.23,340.55,76.19,340.6Z"></path>
                <path className="cls-3" d="M491.75,338.78h-3.7l-120.79,0c-2.52,0-5,.07-7.56,0-1.51-.05-2,.54-2,2q.07,20.24,0,40.48c0,2.23,1.07,2.52,2.92,2.52q40.53,0,81,0c10.54,0,21.09,0,31.63-.09,2.21,0,2.59,1,2.58,2.85q-.07,23.25-.06,46.49a38.88,38.88,0,0,0,.11,4.95c.22,1.68-.3,2.08-2,2.08q-55-.09-110.06-.07a22.52,22.52,0,0,0-4.43.1,2.63,2.63,0,0,0-1.72,1.85c-.06,14.71,0,29.42,0,44.13,0,1.9.5,2.58,2.5,2.57q66.41-.09,132.8-.09c3.76,0,3.81,0,3.81,3.71,0,17.07-.05,34.13,0,51.2,0,2.49-.65,3.19-3.2,3.19q-102.49-.09-205,0c-6.71,0-5.54.85-5.54-5.21q-.06-102.53,0-205c0-17.5.09-35,0-52.51,0-2.5.8-3,3.12-3q95.69.06,191.37,0c4.09,0,8.18,0,12.28,0,1.42,0,2,.42,2,1.94q-.09,27.44-.1,54.86A10.84,10.84,0,0,1,491.75,338.78Z"></path>
                <path className="cls-4" d="M1121.8,280.65c1.44-.13,2.44-.3,3.43-.3,24.41,0,48.81,0,73.21-.06a4.62,4.62,0,0,1,4.58,2.57q11.76,20,23.71,39.88,16.53,27.63,33.12,55.23l4.65,7.74c3.43-5.7,6.64-11,9.83-16.34,16.93-28.25,33.92-56.46,50.65-84.83,1.94-3.29,3.9-4.31,7.55-4.28,21.27.17,42.53.08,63.8.08H1400a15.17,15.17,0,0,1-1,3.59q-25.54,42.92-51.19,85.76-22.72,38.06-45.5,76.07a21.33,21.33,0,0,0-3.27,11.72c.17,28.65.09,57.3.09,85.94,0,3.53,0,3.52-3.41,3.52h-69.28c-3.45,0-3.45,0-3.45-3.51,0-30,0-60.08-.07-90.12a12.17,12.17,0,0,0-1.58-5.87Q1201,413,1180.31,378.73q-23.19-38.69-46.41-77.35-5.49-9.17-11-18.34C1122.56,282.41,1122.3,281.71,1121.8,280.65Z"></path>
                <path className="cls-4" d="M76,133.52q0,64.9,0,129.81c0,2.37-.62,3.12-3.06,3.11q-34.89-.1-69.79,0c-2.55,0-3.22-.78-3.22-3.27Q.09,133.26,0,3.31C0,.82.69.05,3.24.06Q38.27.17,73.3,0c2,0,2.79.6,2.78,2.67,0,9.31,0,18.63,0,27.94q0,51.47,0,102.91Z"></path>
                <path className="cls-4" d="M142.43.06c9.24,0,18.47.06,27.71,0,2.35,0,3,.89,2.92,3.11-.55,10.68-1,21.37-1.48,32.05q-1.47,31.53-3,63.08c-.18,3.82-.41,7.64-.55,11.46-.05,1.47-.73,1.93-2.13,1.93q-23.91,0-47.83,0c-1.64,0-2.11-.76-2.17-2.21-.25-6.35-.59-12.69-.83-19-.6-15.65-1.1-31.3-1.74-46.94-.55-13.39-1.25-26.77-1.87-40.15-.13-2.94.15-3.21,3.22-3.22h27.7Z"></path>
                <path className="cls-4" d="M816.27,542.19q-12-26.91-23.93-53.82-18.87-42.51-37.7-85.05-17.17-38.71-34.37-77.43-9.56-21.57-19-43.17a3.4,3.4,0,0,0-3.51-2.42q-34.9.09-69.81,0a3.22,3.22,0,0,0-3.34,2.27q-20.72,47.09-41.49,94.16Q563,422.16,543,467.55,526.5,504.73,510,541.9c-.64,1.46-1.16,3-1.86,4.82,1.15.09,1.9.21,2.64.21q36,0,71.9.05a3.34,3.34,0,0,0,3.67-2.44c6.21-15.57,12.55-31.09,18.73-46.67.82-2.06,1.9-2.69,4-2.69q53.21.09,106.41,0c1.8,0,3,.26,3.75,2.28,6.14,15.51,12.46,30.94,18.68,46.42.77,1.91,1.57,3.11,4.06,3.1,24.31-.11,48.63-.07,72.94-.08.84,0,1.68-.08,3.11-.14C817.34,544.89,816.86,543.51,816.27,542.19ZM628.74,438.91,662,356.14l.66.1c11,27.43,22,54.87,33.16,82.67Z"></path>
                <path className="cls-4" d="M1113.45,388.62a121.86,121.86,0,0,0-23.91-55.69c-14.84-19.31-34.44-32.18-56.85-41-20-7.89-40.88-11.27-62.23-11.41-42.18-.29-84.37-.13-126.56-.15-3.51,0-3.51,0-3.51,3.67V413.6q0,65.06,0,130.1c0,2.41.5,3.3,3.13,3.3,40.62-.09,81.24,0,121.85-.14a255.79,255.79,0,0,0,29.16-1.71,155.38,155.38,0,0,0,52.12-15.77c16.42-8.36,30.78-19.35,42.19-33.89,14.23-18.14,22.39-38.79,25.35-61.64A156.51,156.51,0,0,0,1113.45,388.62Zm-58.69,26c-1.71,50-41.83,87.92-91.21,87.52-52.47-.43-90.36-42.18-90.29-90.86.31-52.1,42.34-91.6,92.72-90.5S1056.53,362.73,1054.76,414.61Z"></path>
                <path className="cls-3" d="M981,411a16.87,16.87,0,0,1-17.44,17A17.27,17.27,0,0,1,947,410.63c.09-9.15,8.18-17,17.25-16.7C973.83,394.22,981.16,401.65,981,411Z"></path>
              </svg>
            </div>
            {props.children}
          </main>
        </div>
      </div>
    </section>
  }
  export default AuthContainer