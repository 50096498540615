import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "components/common/Loading";
import { AppState } from "store";
import { connect } from "react-redux";
import * as LayoutActions from "store/layout/actions";
import * as LookupsActions from "store/lookups/actions";
import { LookupsState } from "store/lookups/types";
import { toast } from "react-toastify";
import { ApiException } from "api/clients";
import AuthorizeService from "./AuthorizeService";
type LogoutProps = LookupsState & {
  fetchLookups: () => void;
  lastChecked?: Date;
};
function Logout({ data, fetchLookups, lastChecked }: LogoutProps) {
  const navigation = useHistory();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [localLastChecked] = useState(lastChecked);
  useEffect(() => {
    if (data?.user === undefined || isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);
    AuthorizeService.signOut()
      .then(() => {
        fetchLookups();
      })
      .catch((reason: ApiException) => {
        toast.error(reason.message)
        setIsLoggingOut(false);
      });
  }, [isLoggingOut, data, setIsLoggingOut, fetchLookups]);
  useEffect(() => {
    // Only logout when last checked is updated (fetch lookups happens after logout succeeds)
    if (!isLoggingOut || localLastChecked === lastChecked) {
      return;
    }
    setIsLoggingOut(false);
  }, [isLoggingOut, localLastChecked, lastChecked]);
  useEffect(() => {
    if (data?.user === undefined && !isLoggingOut) {
      navigation.push("/");
      return;
    }
  }, [data, isLoggingOut, navigation]);
  if (isLoggingOut) {
    return <Loading color="primary" label="Logging out..."></Loading>;
  }
  return <></>;
}
export default connect((state: AppState) => ({ ...state.lookups }), {
  ...LayoutActions.actionCreators,
  ...LookupsActions.actionCreators
})(Logout);