import initialState from "./state";
import {
  LookupsState,
  LookupsActionTypes,
  SET_LOOKUPS,
  SET_FETCHING_LOOKUPS,
  SET_USER
} from "./types";
export default function lookupsReducer(
  state = initialState,
  action: LookupsActionTypes
): LookupsState {
  switch (action.type) {
    case SET_FETCHING_LOOKUPS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case SET_LOOKUPS:
      return {
        ...state,
        ...action.lookups,
        lastChecked: new Date()
      };
    case SET_USER:
      return {
        ...state,
        data: {
          ...state.data,
          user: action.user
        }
      };
    default:
      return state;
  }
}