export const QueryParameterNames = {
    ReturnUrl: "returnUrl",
    Message: "message"
  };
  export const LogoutActions = {
    Logout: "logout"
  };
  export const LoginActions = {
    Login: "login",
    ForgotPassword: "forgot-password"
  };
  const prefix = "/authentication";
  export const ApplicationPaths = {
    DefaultLoginRedirectPath: "/",
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    ForgotPassword: `${prefix}/${LoginActions.ForgotPassword}`
  };
  export const isAuthenticationPath = (pathname: string) => Object.values(ApplicationPaths).includes(pathname)
  export type SignInState = {
    username: string;
    password: string;
    remember: boolean;
  };