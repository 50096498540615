import { CNav, CNavItem } from '@coreui/react'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class ReportingTabs extends Component<any, any> {

  render() {

    return (
      <CNav variant="tabs" className="mb-3">
        <CNavItem>
          <NavLink className="nav-link" to="/reporting/kits-ordered">Kits Ordered</NavLink>
        </CNavItem>
        <CNavItem>
          <NavLink className="nav-link" to="/reporting/pickups-claimed-vs-deliveries">Pickups Claimed vs Deliveries</NavLink>
        </CNavItem>
        <CNavItem>
          <NavLink className="nav-link" to="/reporting/kits-requested-vs-claimed">Kits Requested vs Claimed</NavLink>
        </CNavItem>
        <CNavItem>
          <NavLink className="nav-link" to="/reporting/surveys-vs-scanned" >Surveys Complete vs Kits Scanned</NavLink>
        </CNavItem>
      </CNav>
    )
  }
}
